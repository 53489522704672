// export const environment = {
//   production: false,
//   PROTOCOL: 'http://',
//   CENTRAL_HOST: 'localhost:8002',
//   BUSES_HOST: 'localhost:8203',
//   CENTRAL_HOST_QA: 'central-qa.z1.mastarjeta.net',
//   CONTROL_HOST: 'localhost:8204',
// };
export const environment = {
   production: false,
   PROTOCOL: 'http://',
   CONTROL_HOST: 'controldestock-qa.z1.mastarjeta.net',
   BILLETERA_HOST: 'billetera.z1.mastarjeta.net',
   CENTRAL_HOST: 'central-qa.z1.mastarjeta.net',
   CENTRAL_HOST_QA: 'central-qa.z1.mastarjeta.net',
   BUSES_HOST: 'buses-qa.z1.mastarjeta.net',
  //  LD_API:  'ld-api.z1.mastarjeta.net',
   LD_API:  '192.168.20.232:8080',
  //  SOCKETIO: 'ws-ld.z1.mastarjeta.net',
   SOCKETIO: 'http://localhost:3000',
  //  TOKEN_SOCKET: '7f5ece0f4cbceb2'
   TOKEN_SOCKET: 'SixRMRotOY5j!w0Ymy80'
 };
// export const environment = {
//   production: false,
//   PROTOCOL: 'http://',
//   CONTROL_HOST: 'dev.mastarjeta.net:8204',
//   CENTRAL_HOST: 'dev.mastarjeta.net:8201',
//   CENTRAL_HOST_QA: 'dev.mastarjeta.net:8201',
//   BUSES_HOST: 'dev.mastarjeta.net:8203'
// };
