import { EventEmitter, Injectable, Output } from '@angular/core';
import {Socket, SocketIoConfig} from 'ngx-socket-io'
import {environment} from '../environments/environment';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class SocketioService extends Socket{
  
  /**
 * Declaramos un metodo de emitir el cual llamaremos "outEven"
 */
@Output() outEven: EventEmitter<any> = new EventEmitter();
@Output() outEvenVenta: EventEmitter<any> = new EventEmitter();
// @Output() outEven: EventEmitter<any> = new EventEmitter();

  constructor(
    
  )  {
    // const token = localStorage.getItem('socket_token');
    
    
    super({
      url: environment.SOCKETIO, options: {
        query: {
          'x-token': localStorage.getItem('socket_token'),
          sala: "dash"
        },
        extraHeaders: {
          'x-token': localStorage.getItem('socket_token'),
          hwid: 'dash',
          sala: "dash"
        }
      }
    });

     /**
         * ---------------- ESCUCHAMOS----------------
         * En este punto nuestro socket.io-client esta listo para recibir los eventos.
         * 
         * En esta funcion vemos como esta preparado para recibir un evento llamado "message" el cual
         * una vez sea recibido va a emitir por nuestro "outEven"
         */

      this.ioSocket.on('inicio-servicio', res => this.outEven.emit(res))
      this.ioSocket.on('venta-boleta', res => this.outEvenVenta.emit(res))
      
  }

  /**
     * ---------------- EMITIR-------------------
     * Ahora solo nos falta poder emitir mensajes, para ello declaramos la funcion
     * "emitEvent" la cual va ser disparada por un "(click)" la cual emite un envento
     * con el nombre "default", y un payload de información el cual sera parseado 
     * por nuestro backend.
     */

   emitEvent = (event = 'emitir',payload = {}) => {
    console.log('emitiendo')
    this.ioSocket.emit('emitir', {
        event,
        payload
    });
}
}
