import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = this.fb.group({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(loginData: any): void {
    this.auth.login(loginData.username, loginData.password);
  }

}
