<div class="card shadow center">
  <!-- Card Header - Dropdown -->
  <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
    <h3 class="m-0 font-weight-bold text-primary">Ingrese sus credenciales</h3>
  </div>
  <!-- Card Body -->
  <div class="card-body">
    <form [formGroup]="loginForm" (ngSubmit)=onSubmit(loginForm.value)>
      <div class="form-group">
        <input type="text"
               class="form-control form-control-user"
               [class.is-invalid]="!loginForm.get('username').valid && loginForm.get('username').dirty"
               formControlName="username"
               placeholder="Usuario">
      </div>
      <div class="form-group">
        <input type="password"
               class="form-control form-control-user"
               [class.is-invalid]="!loginForm.get('password').valid && loginForm.get('password').dirty"
               formControlName="password"
               placeholder="Contraseña">
      </div>
      <p class="text-danger text-center" *ngIf="auth.error.detail">No se pudo autenticar! verifique los datos</p>
      <button type="submit"
              [disabled]="!loginForm.valid"
              class="btn btn-primary btn-user btn-block">
        Acceder
      </button>
    </form>
  </div>
</div>
